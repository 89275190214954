<template>
  <!-- 跑马灯组件 -->
<!--  <div class="marquee-wrap" ref="marquee-wrap">-->
<!--    <div class="scroll" ref="scroll">-->
<!--      <p class="marquee">{{text}}</p>-->
<!--      <p class="copy" ref="copy"></p>-->
<!--    </div>-->
<!--    <p class="getWidth" ref="getWidth">{{text}}</p>-->
<!--  </div>-->
  <div id="scoreapp">
    <div class="box">
      <div class="box-left">
        <span style="font-size: 0.8rem">最新动态:</span>
      </div>
      <div class="box-right">
        <ul :class="{scolltop:istop}">
          <li :key="item.name" v-for="item in listdata">
            <!-- 渲染水果名 -->
            <span>{{item.name}}</span>
            <span>-</span>
            <span class="red">{{item.jkmount}}</span>
            <span>元</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'marquee',
    props: {
      listdata: Array
    },
    data () {
      return {
        timer: null,
        istop: false
      }
    },
    created () {
      // let timer = setTimeout(() => {
      //   this.move()
      //   clearTimeout(timer)
      // }, 1000)
      if (this.listdata.length > 0) {
        setInterval(this.showBox, 5000)
      }
    },
    mounted () {
    },
    methods: {
      showBox () {
        // 该方法开始运行时，让该类名可以使用
        this.istop = true
        setTimeout(() => {
          this.listdata.push(this.listdata[0])
          this.listdata.shift()
          // 运行到内部延时器时，让该类名不能使用
          this.istop = false
        }, 5000)
      },
      beforeDestroy () {
        clearInterval(this.timer)
      }
    }
  }
</script>

<style scoped>

  #scoreapp{
    width: 100%;
    margin: 0 auto;
    position: fixed;
    z-index: 2;
  }
  .box{
    display: flex;
    background-color: antiquewhite;
  }
  .box-left{
    width: 80px;
    text-align: center;
  }
  .box-left span{
    line-height: 30px;
  }
  .box-right{
    height: 30px;
    overflow: hidden;
  }
  .box-right>ul>li{
    line-height: 30px;
  }
  .scolltop{
    transition: all 2s;
    margin-top: -30px;
  }

  .red {
    color: red;
  }
</style>
