<template>
  <div class="headerdiv">
    <headerderail :headertitle="headertitle" :backshow="backshow"></headerderail>
    <marquee :listdata="scoreDataList"></marquee>
    <div class="dultopdiv"  v-if="project">
      <div class="ivu-row" v-if="project">
        <img :src="project.project_image.url" style="width: 100%">
      </div>
      <div class="dl-contentdiv" >
        <cube-tab-bar v-model="selectedLabelSlots" show-slider @click="clickHandler" style="background:white;">
          <cube-tab icon="cubeic-home" label="项目详情" key=1>
          </cube-tab>
          <cube-tab icon="cubeic-like" label="捐款动态" key=2>
          </cube-tab>
        </cube-tab-bar>
        <cube-tab-panels v-model="selectedLabelSlots" style="overflow-y: auto" :style="{position: 'inherit', top: topHeight+30+'px', bottom: '50px',left:'0', right: '0'}">
          <cube-tab-panel label="项目详情" key=1 style="margin-top: 5px">
            <div class="ivu-row" style="background: white;padding: 10px 0" v-if="project">
              <h3 class="newstitle p_2">{{project.title}}</h3>
              <p class="newssubtitle p_2">{{project.sub_title}}</p>
            </div>
            <div style="background:white; margin-top: 5px;padding: 2px;font-size: 0.7rem;" v-if="project" v-html="project.description"></div>
          </cube-tab-panel>
          <cube-tab-panel label="捐款动态" key=2 style="margin-top: 5px">
            <div class="duSearchInput" style="padding: 0px 5px;">
              <cube-input v-model="searchForm.name"></cube-input>
              <cube-button icon="cubeic-search" @click="searchClick"></cube-button>
            </div>
            <ul v-if="feeds.data.length > 0" style="background: white;margin-top: 5px;">
              <li class="tab-panel-li" v-for="(user, index) in feeds.data" :key="index">
                <div class="listitem">
                  <div class="listitem-left">
                    <img v-if="user.head_image.attachment_id !== 0" :src="user.head_image.url" alt="" style="width: 60%">
                    <img v-if="user.head_image.attachment_id === 0" src="../../assets/userimages.png" alt="" style="width: 60%">
                  </div>
                  <div class="listitem-center">
                    <h1 style="font-size: 1.3rem;font-weight: 500;margin-bottom: 0;">{{user.name}} <span style="font-size: .8rem">{{user.village}}</span></h1>
                    <p style="font-size: .7rem;font-weight: 500;">
                      捐款总金额 <span style="font-size: 1rem;color: red;font-weight: 600;"> {{user.jkmount}}</span> 元
                    </p>
                    <p style="font-size: .6rem;">{{user.comein_at.slice(0,10)}}</p>
                  </div>
                  <!--            <div class="listitem-right oneitem_center">-->
                  <!--              <h1 style="margin-right: 10px;color: red;font-size: 1.2rem;"> <span style="font-size: .8rem;font-weight: 500;color: black">总捐款</span>￥{{user.jkmount}}</h1>-->
                  <!--            </div>-->
                  <div class="listitem-right" style="text-align: center;">
                    <h1 style="margin-right: 10px;color: red;font-size: 1.5rem;"> ￥{{user.ujkmount}}</h1>
                    <h5 style="font-size: .8rem;font-weight: 400;">项目捐款</h5>
                  </div>
                </div>
              </li>
            </ul>

            <ul v-if="feeds.data.length === 0" style="width: 100%;text-align: center;background: white;margin-top: 2px">
              <img src="../../assets/nocartdata.png" alt="" width="100" style="margin: auto; display: block;padding: 10px 0">
              <h2 style="margin-top: 10px;">暂时还没同乡捐款</h2>
            </ul>
          </cube-tab-panel>
        </cube-tab-panels>
      </div>
    </div>

    <div class="donation_buttom">
      <cube-button :primary="true" style="margin-left: 0%" @click="donationClick(0)">立即捐款</cube-button>
<!--      <cube-button :primary="true" @click="donationClick(1)">帮朋友捐款</cube-button>-->
    </div>
    <cube-popup type="my-popup" position="bottom" :mask-closable="true" ref="myPopup4">
      <div class="donation_self" v-show="donationSelfShow">
        <cube-form :model="donation" @validate="validateHandler" @submit.prevent="selfdonationClick">
          <cube-form-group>
            <cube-form-item :field="fields[0]"></cube-form-item>
            <cube-form-item :field="fields[1]"></cube-form-item>
            <cube-form-item :field="fields[2]">
            </cube-form-item>
            <cube-form-item :field="fields[3]">
            </cube-form-item>
          </cube-form-group>
          <cube-form-group>
            <cube-button :primary="true" type="submit">立即捐款</cube-button>
          </cube-form-group>
        </cube-form>
      </div>
      <div class="donation_other" v-show="!donationSelfShow">
        <cube-form :model="donation" @validate="validateHandler" @submit.prevent="submitHandler">
          <cube-form-group>
            <cube-form-item :field="fields[0]"></cube-form-item>
            <cube-form-item :field="fields[1]"></cube-form-item>
            <cube-form-item :field="fields[2]">
            </cube-form-item>
            <cube-form-item :field="fields[3]">
            </cube-form-item>
          </cube-form-group>
          <cube-form-group>
            <cube-button :primary="true" type="submit">立即捐款</cube-button>
          </cube-form-group>
        </cube-form>
      </div>
    </cube-popup>
  </div>
</template>

<script>
    import headerderail from 'cp/v-header/headerdetail.vue'
    import marquee from 'cp/scolltext.vue'
    import { cloneArray } from 'libs/tools'
    import {
      getDonationUserList,
      checkUser,
      wxPayParams,
      userDonation
    } from 'api/user'
    import { todoStorage } from 'libs/storage'
    export default {
      name: 'donation',
      components: {
        headerderail,
        marquee
      },
      data () {
        return {
          topHeight: 0,
          project: null,
          donation: {
            name: '',
            phone: '',
            value: '',
            village: ''
          },
          backshow: true,
          donationButtomShowNot: true,
          donationSelfShow: true,
          headertitle: '项目捐款',
          selectedLabelSlots: '项目详情',
          tabs: [
            {
              label: '项目详情',
              icon: 'cubeic-home'
            },
            {
              label: '捐款动态',
              icon: 'cubeic-like'
            }
          ],
          searchForm: {
            name: ''
          },
          pSearchForm: {
            order_by: 'id,desc',
            title: '',
            enable: ''
          },
          feeds: {
            data: [],
            total: 0,
            current_page: 1,
            per_page: 10
          },
          scoreDataList: [],
          pFeeds: {
            data: [],
            total: 0,
            current_page: 1,
            per_page: 10
          },
          fields: [
            {
              type: 'input',
              modelKey: 'name',
              label: '姓名：',
              props: {
                placeholder: '请输入您的姓名',
                type: 'text'
              },
              rules: {
                required: true
              }
            },
            {
              type: 'input',
              modelKey: 'phone',
              label: '电话：',
              props: {
                placeholder: '请输入您的电话',
                type: 'number'
              },
              rules: {
                required: true
              }
            },
            {
              type: 'input',
              modelKey: 'value',
              label: '金额：',
              props: {
                placeholder: '请输入您要捐款的金额',
                type: 'number'
              },
              rules: {
                required: true
              }
            },
            {
              type: 'input',
              modelKey: 'village',
              label: '村名：',
              props: {
                placeholder: '新会员，一定要输入村名',
                type: 'text'
              }
            }
          ]
        }
      },
      mounted () {
        // this.project = todoStorage.getSelectProject
        // console.log(this.project)
        this.topHeight = document.body.clientWidth / 2 + 50
        this.getDataExcute(0)
      },
      methods: {
        searchClick () {
          let t = this
          t.feeds.data = []
          t.getDataExcute(1)
        },
        selfdonationClick () {
          let t = this
          t.showLoading()
          checkUser(t.donation).then(res => {
            console.log(res)
            todoStorage.setUserData = res.data
            todoStorage.setUserIsLogin = true
            var userid = res.data.id
            var data = {
              id: userid,
              jkmount: t.donation.value,
              donation_id: t.$route.params['donationid'],
              openid: todoStorage.getWxOpenid
            }
            const component = t.$refs.myPopup4
            wxPayParams(data).then(res => {
              window.WeixinJSBridge.invoke(
                'getBrandWCPayRequest',
                JSON.parse(res.data),
                function (res) {
                  if (res.err_msg === 'get_brand_wcpay_request:ok') {
                    t.NoteTips('捐款成功')
                    userDonation(data).then(res => {
                      location.reload()
                    }, function (error) {
                      console.log(error)
                      t.hideLoading()
                    })
                  } else if (res.err_msg === 'get_brand_wcpay_request:cancel') {
                    t.NoteTips('取消支付')
                    t.hideLoading()
                    component.hide()
                  } else {
                    t.NoteTips('支付失败')
                    t.hideLoading()
                  }
                }
              )
            }, function (error) {
              t.NoteTips('捐款失败')
              t.hideLoading()
              location.reload()
              console.log(error)
            })
            t.hideLoading()
          }, function (error) {
            console.log(error)
            t.hideLoading()
          })
        },
        submitHandler (e) {
          console.log('submit')
          let userdata = todoStorage.getUserData
          if (userdata) {

          } else {
            var t = this
            t.$router.push({ name: 'login' })
          }
        },
        validateHandler (result) {
          // this.validity = result.validity
          // this.valid = result.valid
          // console.log('validity', result.validity, result.valid, result.dirty, result.firstInvalidFieldIndex)
        },
        donationClick (index) {
          let t = this
          const component = t.$refs.myPopup4
          component.show()
          if (index === 0) {
            t.donationSelfShow = true
          } else {
            t.donationSelfShow = false
          }
        },
        clickHandler (label) {
          // if you clicked home tab, then print 'Home'
          console.log(label)
          switch (label) {
            case '慈善榜':
              this.donationButtomShowNot = true
              break
            case '捐款动态':
              this.donationButtomShowNot = false
              break
          }
        },
        getDataExcute (topage) {
          let t = this
          t.showLoading()
          // t.searchForm.donation_id = t.$route.params['donationid']
          console.log(t.$route.params['donationid'])
          getDonationUserList(t.$route.params['donationid'], t.searchForm.name).then(res => {
            t.feeds.data = res.data.userdpdata
            console.log(res)
            t.scoreDataList = cloneArray(res.data.userdpdata)
            // if (t.project === null || t.project === undefined) {
            t.project = res.data.project
            //   todoStorage.setSelectProject = res.data.project
            // }
            document.title = t.project.title
            document.querySelector('meta[name="description"]').setAttribute('content', t.project.sub_title)
            // t.feeds.total = res.meta.total
          }, function (error) {
            t.hideLoading()
            console.log(error)
          })
        }
      }
    }
</script>

<style lang='stylus' rel='stylesheet/stylus'>
  @import 'list.styl'
</style>
